import React from 'react';
import styled from 'styled-components';

const CompetenciesSection = styled.section`
  padding: 4rem 2rem;
  background: #2a2a2a;
  color: white;
`;

const CompetenciesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CompetenciesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const CompetencyCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #64ffda;
    transform: scaleY(0);
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: scaleY(1);
  }
`;

const CompetencyTitle = styled.h3`
  color: #64ffda;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const CompetencyDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  opacity: 0.9;
`;

const Competencies: React.FC = () => {
  const competencies = [
    {
      title: 'Полный цикл разработки',
      description: 'От проектирования до развертывания и поддержки'
    },
    {
      title: 'Оптимизация производительности',
      description: 'Улучшение быстродействия и эффективности приложений'
    },
    {
      title: 'Проектирование архитектуры',
      description: 'Создание масштабируемых и поддерживаемых решений'
    },
    {
      title: 'Интеграция технологий',
      description: 'Объединение различных технологий в единое решение'
    },
    {
      title: 'Работа с legacy-кодом',
      description: 'Поддержка и модернизация существующих систем'
    },
    {
      title: 'Масштабируемые решения',
      description: 'Разработка систем с учетом будущего роста'
    },
    {
      title: 'Качество кода',
      description: 'Внимание к деталям и высокие стандарты разработки'
    },
    {
      title: 'Технологическая гибкость',
      description: 'Работа с различными технологическими стеками'
    }
  ];

  return (
    <CompetenciesSection>
      <CompetenciesContainer>
        <h2>Ключевые компетенции</h2>
        <CompetenciesGrid>
          {competencies.map((competency, index) => (
            <CompetencyCard key={index}>
              <CompetencyTitle>{competency.title}</CompetencyTitle>
              <CompetencyDescription>{competency.description}</CompetencyDescription>
            </CompetencyCard>
          ))}
        </CompetenciesGrid>
      </CompetenciesContainer>
    </CompetenciesSection>
  );
};

export default Competencies;
