import React from 'react';
import styled, { keyframes } from 'styled-components';

const typeAndDelete = keyframes`
  0%, 100% { width: 0 }
  50% { width: 100% }
`;

const blink = keyframes`
  50% { border-color: transparent }
`;

const Container = styled.div`
  position: absolute;
  right: 200px;
  top: 100px;
  width: 300px;
  font-family: 'Fira Code', monospace;
  color: #64ffda;
  opacity: 0.4;
  z-index: 1;
`;

const CodeLine = styled.div<{ delay: number; withMargin?: boolean; indentLevel?: number }>`
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  animation: ${typeAndDelete} 8s steps(40, end) ${props => props.delay}s infinite;
  
  ${props => props.withMargin && `
    margin-top: 20px;
  `}

  ${props => props.indentLevel && `
    margin-left: ${props.indentLevel * 15}px;
  `}
  
  &::before {
    content: '>';
    margin-right: 10px;
    color: #64ffda;
  }
`;

const Cursor = styled.span`
  border-right: 2px solid #64ffda;
  animation: ${blink} 1s step-end infinite;
`;

const Comment = styled.span`
  color: #6272a4;
`;

const Keyword = styled.span`
  color: #ff79c6;
`;

const String = styled.span`
  color: #f1fa8c;
`;

const Function = styled.span`
  color: #50fa7b;
`;

const Number = styled.span`
  color: #bd93f9;
`;

const Boolean = styled.span`
  color: #bd93f9;
`;

const CodeDecoration: React.FC = () => {
  return (
    <Container>
      <CodeLine delay={0}>
        <Keyword>const</Keyword> portfolio = <Function>createDeveloper</Function>
      </CodeLine>
      <CodeLine delay={1} withMargin indentLevel={1}>
        <Comment>{'// Профессиональные навыки'}</Comment>
      </CodeLine>
      <CodeLine delay={2} indentLevel={1}>
        {'skills: ['}<String>"TypeScript"</String>, <String>"React"</String>{'],'} 
      </CodeLine>
      <CodeLine delay={3} withMargin indentLevel={2}>
        <Comment>{'// Опыт работы'}</Comment>
      </CodeLine>
      <CodeLine delay={4} indentLevel={2}>
        {'experience: {'}<String>"years"</String>: <Number>3</Number>{'},'}
      </CodeLine>
      <CodeLine delay={5} withMargin indentLevel={3}>
        <Comment>{'// Игровая разработка'}</Comment>
      </CodeLine>
      <CodeLine delay={6} indentLevel={3}>
        {'gamedev: {'}
      </CodeLine>
      <CodeLine delay={7} indentLevel={3}>
        {'  minecraft: {'}<String>"plugins"</String>: <Number>50</Number>{'+},'}
      </CodeLine>
      <CodeLine delay={8} indentLevel={3}>
        {'  More others: {'}<String>"API"</String>: <Number>10</Number>{'},'}
      </CodeLine>
      <CodeLine delay={9} indentLevel={3}>
        {'},'}
      </CodeLine>
      <CodeLine delay={10} withMargin indentLevel={4}>
        <Comment>{'// Характеристики'}</Comment>
      </CodeLine>
      <CodeLine delay={11} indentLevel={4}>
        {'stats: {'}
      </CodeLine>
      <CodeLine delay={12} indentLevel={4}>
        {'  codingSpeed: {'}<String>"wpm"</String>: <Number>80</Number>{'},'}
      </CodeLine>
      <CodeLine delay={13} indentLevel={4}>
        {'  debugLevel: {'}<String>"skill"</String>: <String>"expert"</String>{'},'}
      </CodeLine>
      <CodeLine delay={14} indentLevel={4}>
        {'  coffeeNeeded: {'}<String>"cups"</String>: <Number>2</Number>{'/day},'}
      </CodeLine>
      <CodeLine delay={15} indentLevel={4}>
        {'  readyForTasks: '}<Boolean>true</Boolean>{','}
      </CodeLine>
      <CodeLine delay={16} withMargin indentLevel={5}>
        <Comment>{'// Предпочтения'}</Comment>
      </CodeLine>
      <CodeLine delay={17} indentLevel={5}>
        {'preferences: {'}
      </CodeLine>
      <CodeLine delay={18} indentLevel={5}>
        {'  ide: {'}<String>"Git"</String>: <String>"VS Code"</String>{'},'}
      </CodeLine>
      <CodeLine delay={19} indentLevel={5}>
        {'  theme: {'}<String>"dark"</String>: <Boolean>true</Boolean>{'},'}
      </CodeLine>
      <CodeLine delay={20} indentLevel={5}>
        {'  music: ['}<String>"hiphop"</String>, <String>"rap"</String>{'],'}
      </CodeLine>
      <CodeLine delay={21} indentLevel={5}>
        {'},'}
      </CodeLine>
      <CodeLine delay={22} withMargin indentLevel={6}>
        <Comment>{'// Достижения'}</Comment>
      </CodeLine>
      <CodeLine delay={23} indentLevel={6}>
        {'achievements: {'}
      </CodeLine>
      <CodeLine delay={24} indentLevel={6}>
        {'  bugsFixed: {'}<String>"count"</String>: <String>"∞"</String>{'},'}
      </CodeLine>
      <CodeLine delay={25} indentLevel={6}>
        {'  allNighters: {'}<String>"survived"</String>: <Number>42</Number>{'},'}
      </CodeLine>
      <CodeLine delay={26} indentLevel={6}>
        {'  codeReviews: {'}<String>"status"</String>: <String>"✓ passed"</String>{'}'}
      </CodeLine>
      <CodeLine delay={27} indentLevel={6}>
        {'}'}<Cursor>|</Cursor>
      </CodeLine>
    </Container>
  );
};

export default CodeDecoration;
