import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCode, FaProjectDiagram, FaClock, FaUsers } from 'react-icons/fa';

const StatsContainer = styled(motion.div)`
  width: 100%;
  padding: 2rem 0;
  background: var(--light-navy);
  border-radius: 10px;
  margin: 2rem 0;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
`;

const StatItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  
  svg {
    color: var(--green);
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
`;

const StatValue = styled(motion.span)`
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--lightest-slate);
  font-family: var(--font-mono);
  margin: 0.5rem 0;
`;

const StatLabel = styled.span`
  color: var(--slate);
  font-size: 1rem;
`;

const Stats: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  const countVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        duration: 0.8
      }
    }
  };

  return (
    <StatsContainer
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
    >
      <StatsGrid>
        <StatItem variants={itemVariants}>
          <FaProjectDiagram />
          <StatValue variants={countVariants}>50+</StatValue>
          <StatLabel>Завершенных проектов</StatLabel>
        </StatItem>
        
        <StatItem variants={itemVariants}>
          <FaCode />
          <StatValue variants={countVariants}>500K+</StatValue>
          <StatLabel>Строк кода</StatLabel>
        </StatItem>
        
        <StatItem variants={itemVariants}>
          <FaClock />
          <StatValue variants={countVariants}>3+</StatValue>
          <StatLabel>Года опыта</StatLabel>
        </StatItem>
        
        <StatItem variants={itemVariants}>
          <FaUsers />
          <StatValue variants={countVariants}>50+</StatValue>
          <StatLabel>Активных заказчиков</StatLabel>
        </StatItem>
      </StatsGrid>
    </StatsContainer>
  );
};

export default Stats;
