import React from 'react';
import styled from 'styled-components';

const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
`;

const LogoSvg = styled.svg`
  width: 100%;
  height: 100%;
  fill: none;
  transition: transform 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  
  &:hover {
    transform: rotate(360deg);
  }
`;

const HexagonPath = styled.path`
  stroke: #64ffda;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  filter: drop-shadow(0 0 8px rgba(100, 255, 218, 0.3));
`;

const LogoText = styled.text`
  font-family: var(--font-mono);
  font-size: 15px;
  font-weight: 600;
  fill: #64ffda;
  text-anchor: middle;
`;

const Logo = () => {
  return (
    <LogoLink href="#home">
      <LogoSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <title>Logo</title>
        <HexagonPath d="M50 5 L89 28 L89 73 L50 95 L11 73 L11 28 Z" />
        <LogoText x="50" y="45">MR</LogoText>
        <LogoText x="50" y="65">ZV</LogoText>
      </LogoSvg>
    </LogoLink>
  );
};

export default Logo;
