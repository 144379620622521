import React from 'react';
import styled from 'styled-components';
import Snow from '../Snow/Snow';

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999;
`;

const SnowOverlay: React.FC = () => {
  return (
    <OverlayContainer>
      <Snow />
    </OverlayContainer>
  );
};

export default SnowOverlay;
