import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaGithub, FaVk, FaGamepad, FaDiceD6 } from 'react-icons/fa';
import { SiPastebin, SiRust } from 'react-icons/si';
import CodeDecoration from '../CodeDecoration/CodeDecoration';
import Logo from '../Logo/Logo';

const HeaderContainer = styled.header`
  padding: 0;
  background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    top: -50%;
    left: -50%;
    animation: rotate 20s linear infinite;
    pointer-events: none;
    z-index: 0;
  }
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 10;
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 2;
`;

const MainContent = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

const SocialLinks = styled.div`
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  gap: 1.5rem;
  z-index: 10;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 1.8rem;
  transition: all 0.3s ease;
  
  &:hover {
    color: #64ffda;
    transform: translateY(-2px);
  }
`;

const TypewriterContainer = styled.div`
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  font-weight: 700;
  position: relative;
  z-index: 1;
  color: #64ffda;
  line-height: 1.2;
  font-family: var(--font-mono);
  height: 4.2rem;
  display: flex;
  align-items: center;
`;

const Cursor = styled.span`
  display: inline-block;
  width: 3px;
  height: 1em;
  background-color: #64ffda;
  margin-left: 4px;
  animation: blink 0.8s infinite;
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;

const Typewriter = () => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [delta, setDelta] = useState(150);
  const [isRussian, setIsRussian] = useState(true);

  const russianText = 'Александр Морозов';
  const englishText = 'Alexander Morozov';
  const fullText = isRussian ? russianText : englishText;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => clearInterval(ticker);
  }, [text, delta]);

  const tick = useCallback(() => {
    let updatedText = isDeleting 
      ? text.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(100);
    }

    if (!isDeleting && updatedText === fullText) {
      setDelta(150);
      setIsDeleting(true);
    } else if (isDeleting && updatedText === '') {
      setDelta(150);
      setIsDeleting(false);
      setIsRussian(!isRussian);
    }
  }, [text, isDeleting, fullText, delta, isRussian]);

  return (
    <TypewriterContainer>
      <span>{text}</span>
      <Cursor />
    </TypewriterContainer>
  );
};

const Title = styled.h2`
  font-size: 2rem;
  color: #64ffda;
  margin: 0.5rem 0;
  padding: 0;
  position: relative;
  z-index: 1;
`;

const Description = styled.div`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 1rem 0;
  color: #8892b0;
  line-height: 1.5;

  span.highlight {
    color: #64ffda;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
  }

  li {
    position: relative;
    padding-left: 20px;
    margin: 0.5rem 0;
    
    &:before {
      content: '▹';
      position: absolute;
      left: 0;
      color: #64ffda;
    }
  }
`;

const ExperienceSection = styled.div`
  margin-top: 1.5rem;
  position: relative;
  z-index: 1;
`;

const ExperienceTitle = styled.h3`
  font-size: 1.5rem;
  color: #64ffda;
  margin: 1rem 0;
`;

const ExperienceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
`;

const ExperienceItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 1.2rem;
    color: #64ffda;
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <SocialLinks>
        <SocialIcon href="https://github.com/mrzv-afk" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </SocialIcon>
        <SocialIcon href="https://vk.com/id544728463" target="_blank" rel="noopener noreferrer">
          <FaVk />
        </SocialIcon>
        <SocialIcon href="https://pastebin.com/u/mrzv04" target="_blank" rel="noopener noreferrer">
          <SiPastebin />
        </SocialIcon>
      </SocialLinks>
      <ContentWrapper>
        <MainContent>
          <CodeDecoration />
          <Typewriter />
          <Title>Fullstack Middle Developer</Title>
          <Description>
            Опытный fullstack-разработчик с большим стеком и знаниями в различных языках программирования.
            Специализируюсь на разработке комплексных решений от низкоуровневого программирования до сложных 
            скриптов и лучших плагинов.
          </Description>

          <ExperienceSection>
            <ExperienceTitle>Опыт разработки для Minecraft серверов</ExperienceTitle>
            <ExperienceList>
              <ExperienceItem>
                <FaDiceD6 />
                MST Network
              </ExperienceItem>
              <ExperienceItem>
                <FaDiceD6 />
                HolyWorld
              </ExperienceItem>
              <ExperienceItem>
                <FaDiceD6 />
                SunRise
              </ExperienceItem>
              <ExperienceItem>
                <FaDiceD6 />
                ToyCube
              </ExperienceItem>
            </ExperienceList>
          </ExperienceSection>

          <ExperienceSection>
            <ExperienceTitle>Опыт разработки для GTA серверов</ExperienceTitle>
            <ExperienceList>
              <ExperienceItem>
                <FaGamepad />
                Arizona V
              </ExperienceItem>
              <ExperienceItem>
                <FaGamepad />
                Grand5RP
              </ExperienceItem>
              <ExperienceItem>
                <FaGamepad />
                Crystal
              </ExperienceItem>
              <ExperienceItem>
                <FaGamepad />
                Другие сервера
              </ExperienceItem>
            </ExperienceList>
          </ExperienceSection>

          <ExperienceSection>
            <ExperienceTitle>Опыт разработки для других игр</ExperienceTitle>
            <ExperienceList>
              <ExperienceItem>
                <SiRust />
                Rust
              </ExperienceItem>
              <ExperienceItem>
                <FaGamepad />
                Другие игры
              </ExperienceItem>
            </ExperienceList>
          </ExperienceSection>
        </MainContent>
      </ContentWrapper>
      <CodeDecoration />
    </HeaderContainer>
  );
};

export default Header;
