import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LoaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navy);
  z-index: 99;
`;

const LogoContainer = styled(motion.div)`
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HexagonSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HexagonPath = styled(motion.path)`
  fill: none;
  stroke: #64ffda;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  filter: drop-shadow(0 0 8px rgba(100, 255, 218, 0.3));
`;

const TextContainer = styled(motion.div)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: #64ffda;
  font-family: var(--font-mono);
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(100, 255, 218, 0.3);
`;

const TextLine = styled(motion.div)`
  display: flex;
  gap: 1px;
  line-height: 1;
`;

const Letter = styled(motion.span)``;

interface LoaderProps {
  finishLoading: () => void;
}

const Loader: React.FC<LoaderProps> = ({ finishLoading }) => {
  useEffect(() => {
    const timeout = setTimeout(() => finishLoading(), 2600);
    return () => clearTimeout(timeout);
  }, [finishLoading]);

  return (
    <LoaderContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <LogoContainer
        initial={{ scale: 0, rotate: -180 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{
          duration: 0.8,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
      >
        <HexagonSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <HexagonPath
            initial={{ pathLength: 0, opacity: 0 }}
            animate={{ pathLength: 1, opacity: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            d="M50 5 L89 28 L89 73 L50 95 L11 73 L11 28 Z"
          />
        </HexagonSvg>
        <TextContainer>
          <TextLine>
            {['M', 'R'].map((letter, i) => (
              <Letter
                key={letter}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.8 + i * 0.1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
              >
                {letter}
              </Letter>
            ))}
          </TextLine>
          <TextLine>
            {['Z', 'V'].map((letter, i) => (
              <Letter
                key={letter}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 1.0 + i * 0.1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
              >
                {letter}
              </Letter>
            ))}
          </TextLine>
        </TextContainer>
      </LogoContainer>
    </LoaderContainer>
  );
};

export default Loader;
