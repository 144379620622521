import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import Header from './components/Header/Header';
import Skills from './components/Skills/Skills';
import Competencies from './components/Competencies/Competencies';
import GlobalStyles from './styles/GlobalStyles';
import Loader from './components/Loader/Loader';
import SnowOverlay from './components/SnowOverlay/SnowOverlay';

const MainContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background: var(--navy);
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Section = styled(motion.section)`
  position: relative;
`;

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.96,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
      ease: [0.43, 0.13, 0.23, 0.96],
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 1.04,
    transition: {
      duration: 0.6,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const sectionVariants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preloadFonts = async () => {
      try {
        await document.fonts.ready;
      } catch (error) {
        console.error('Error loading fonts:', error);
      }
    };

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    preloadFonts();

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <GlobalStyles />
      <MainContainer>
        <SnowOverlay />
        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loader"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Loader finishLoading={() => setIsLoading(false)} />
            </motion.div>
          ) : (
            <ContentWrapper>
              <Section
                variants={pageVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <Section variants={sectionVariants}>
                  <Header />
                </Section>
                <Section variants={sectionVariants}>
                  <Skills />
                </Section>
                <Section variants={sectionVariants}>
                  <Competencies />
                </Section>
              </Section>
            </ContentWrapper>
          )}
        </AnimatePresence>
      </MainContainer>
    </>
  );
};

export default App;
