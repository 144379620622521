import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import mixins from '../styles/mixins';

interface Theme {
  colors: {
    darkNavy: string;
    navy: string;
    lightNavy: string;
    lightestNavy: string;
    navyShadow: string;
    slate: string;
    lightSlate: string;
    lightestSlate: string;
    white: string;
    green: string;
    greenTint: string;
  };
  mixins: any;
}

const lightTheme: Theme = {
  colors: {
    darkNavy: '#e6e6e6',
    navy: '#f5f5f5',
    lightNavy: '#e0e0e0',
    lightestNavy: '#d4d4d4',
    navyShadow: 'rgba(0, 0, 0, 0.1)',
    slate: '#4a4a4a',
    lightSlate: '#2a2a2a',
    lightestSlate: '#1a1a1a',
    white: '#000000',
    green: '#64ffda',
    greenTint: 'rgba(100, 255, 218, 0.1)'
  },
  mixins
};

const darkTheme: Theme = {
  colors: {
    darkNavy: '#020c1b',
    navy: '#0a192f',
    lightNavy: '#112240',
    lightestNavy: '#233554',
    navyShadow: 'rgba(2, 12, 27, 0.7)',
    slate: '#8892b0',
    lightSlate: '#a8b2d1',
    lightestSlate: '#ccd6f6',
    white: '#e6f1ff',
    green: '#64ffda',
    greenTint: 'rgba(100, 255, 218, 0.1)'
  },
  mixins
};

interface ThemeContextType {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType>({
  theme: 'dark',
  toggleTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>('dark');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <StyledThemeProvider theme={currentTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
