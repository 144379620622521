import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { 
  SiTypescript, 
  SiJavascript, 
  SiReact, 
  SiVuedotjs, 
  SiNodedotjs, 
  SiPython, 
  SiCplusplus,
  SiCsharp, 
  SiMysql, 
  SiPostgresql, 
  SiDocker, 
  SiKubernetes, 
  SiGit, 
  SiLinux, 
  SiWindows,
  SiHtml5, 
  SiCss3, 
  SiAndroid, 
  SiKotlin,
  SiLua,
  SiFirebase,
  SiAmazon,
  SiGoogle,
  SiMicrosoft,
  SiSwift,
  SiUnity,
  SiUnrealengine,
  SiBlender,
  SiAdobephotoshop
} from 'react-icons/si';
import { FaJava } from 'react-icons/fa';
import Stats from '../Stats/Stats';

const SkillsSection = styled.section`
  min-height: 100vh;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  color: #ffffff;
  position: relative;
  z-index: 2;
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #64ffda;
`;

const Description = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
`;

const SkillsList = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
`;

const SkillCategory = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(100, 255, 218, 0.1);
  }
`;

const CategoryTitle = styled.h3`
  color: #64ffda;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #64ffda;
    transition: width 0.3s ease;
  }

  ${SkillCategory}:hover &:after {
    width: 100%;
  }
`;

const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkillItem = styled(motion.div)`
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease, color 0.2s ease;
  
  &:before {
    content: "▹";
    color: #64ffda;
    margin-right: 1rem;
    transition: transform 0.2s ease;
  }

  &:hover {
    transform: translateX(10px);
    color: #64ffda;

    &:before {
      transform: scale(1.2);
    }
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { 
    opacity: 0, 
    y: 20,
    scale: 0.95
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.8,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const skillItemVariants = {
  hidden: { 
    opacity: 0, 
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const Skills: React.FC = () => {
  return (
    <SkillsSection>
      <Content>
        <Title
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={itemVariants}
        >
          Мои навыки
        </Title>
        
        <Description
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={itemVariants}
        >
          Разработка мобильных и веб-приложений, сайтов и сложных интерфейсов,
          работа с модами, скриптами, плагинами и API, используя передовые технологии и лучшие практики разработки.
          Вот краткий обзор моих технических навыков и опыта:
        </Description>

        <Stats />
        
        <SkillsList
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
        >
          <SkillCategory>
            <CategoryTitle>Языки</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiTypescript />TS / <SiJavascript / >JavaScript</SkillItem>
              <SkillItem variants={itemVariants}><SiLua />Lua / Pawn </SkillItem>
              <SkillItem variants={itemVariants}><SiPython />Python</SkillItem>
              <SkillItem variants={itemVariants}><FaJava />Java</SkillItem>
              <SkillItem variants={itemVariants}><SiCsharp />C# / C++</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>Frontend</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiReact />React</SkillItem>
              <SkillItem variants={itemVariants}><SiVuedotjs />Vue.js</SkillItem>
              <SkillItem variants={itemVariants}><SiHtml5 />HTML5</SkillItem>
              <SkillItem variants={itemVariants}><SiCss3 />CSS3</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>Backend & Базы данных</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiNodedotjs />Node.js</SkillItem>
              <SkillItem variants={itemVariants}><SiPostgresql />PostgreSQL</SkillItem>
              <SkillItem variants={itemVariants}><SiMysql />MySQL</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>DevOps & Инструменты</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiDocker />Docker</SkillItem>
              <SkillItem variants={itemVariants}><SiKubernetes />Kubernetes</SkillItem>
              <SkillItem variants={itemVariants}><SiGit />Git</SkillItem>
              <SkillItem variants={itemVariants}><SiLinux />Linux</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>Cloud & Services</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiFirebase />Firebase</SkillItem>
              <SkillItem variants={itemVariants}><SiAmazon />AWS</SkillItem>
              <SkillItem variants={itemVariants}><SiGoogle />Google Cloud</SkillItem>
              <SkillItem variants={itemVariants}><SiMicrosoft />Azure</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>Мобильная разработка</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiAndroid />Android</SkillItem>
              <SkillItem variants={itemVariants}><SiKotlin />Kotlin</SkillItem>
              <SkillItem variants={itemVariants}><SiSwift />Swift</SkillItem>
            </CategoryItems>
          </SkillCategory>

          <SkillCategory>
            <CategoryTitle>Game Dev & 3D</CategoryTitle>
            <CategoryItems>
              <SkillItem variants={itemVariants}><SiUnity />Unity</SkillItem>
              <SkillItem variants={itemVariants}><SiUnrealengine />Unreal Engine</SkillItem>
              <SkillItem variants={itemVariants}><SiBlender />Blender</SkillItem>
              <SkillItem variants={itemVariants}><SiAdobephotoshop />Photoshop</SkillItem>
            </CategoryItems>
          </SkillCategory>
        </SkillsList>
      </Content>
    </SkillsSection>
  );
};

export default Skills;
