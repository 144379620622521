import React from 'react';
import styled, { keyframes } from 'styled-components';

const snowfall = keyframes`
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 100vh, 0);
  }
`;

const SnowContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const SnowflakeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface SnowflakeProps {
  size: number;
  left: number;
  opacity: number;
  delay: number;
  duration: number;
}

const Snowflake = styled.div<SnowflakeProps>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: white;
  border-radius: 50%;
  left: ${props => props.left}%;
  opacity: ${props => props.opacity};
  animation: ${snowfall} ${props => props.duration}s linear infinite;
  animation-delay: -${props => props.delay}s;
`;

const Snow: React.FC = () => {
  const snowflakes = Array.from({ length: 50 }, (_, i) => ({
    key: i,
    size: Math.random() * 3 + 2,
    left: Math.random() * 100,
    opacity: Math.random() * 0.6 + 0.2,
    delay: Math.random() * 20,
    duration: Math.random() * 3 + 8,
  }));

  return (
    <SnowContainer>
      <SnowflakeContainer>
        {snowflakes.map(flake => (
          <Snowflake
            key={flake.key}
            size={flake.size}
            left={flake.left}
            opacity={flake.opacity}
            delay={flake.delay}
            duration={flake.duration}
          />
        ))}
      </SnowflakeContainer>
    </SnowContainer>
  );
};

export default Snow;
